import React from 'react';
import Calendar from '../../components/Calendar/Calendar';

const CalendarPage = () => {
  return (
    <section id="Calendar" className="calendar--section">
      <div>
        <h1 id="CalendarHeader" className="calendar--section--heading">Event Calendar</h1>
        <Calendar />
        <div className="calendar--section--button">
          <a 
            href="https://docs.google.com/forms/d/e/1FAIpQLSdwGDRBQUF9yKyFmDw8Y5hHCAAs9k9rxnu68RoJS5v3lOOtWg/viewform" 
            target="_blank" 
            rel="noopener noreferrer"
            className="btn btn-primary"
          >
            First class? Sign the waiver here!
          </a>
        </div>
      </div>
    </section>
  );
};

export default CalendarPage;
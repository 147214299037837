import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import EventList from './EventList'; 
import 'react-calendar/dist/Calendar.css';
import '../../style/App.css';
import '../../style/Calendar.css';

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [date, setDate] = useState(new Date());
  const [error, setError] = useState(null);

  const API_GATEWAY_URL = 'https://414vhwj8f2.execute-api.us-east-1.amazonaws.com/Prod/events';

  //fetch the events
  const fetchEvents = async () => {
    try {
      const response = await fetch(API_GATEWAY_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      //response is an array of event objects
      const data = await response.json();

      //format each event for React Calendar or your UI
      const formattedEvents = data.map((event) => ({
        id: event.id,
        title: event.summary,
        //if needed, handle dateTime vs. date
        start: new Date(event.start.dateTime || event.start.date),
        end: new Date(event.end.dateTime || event.end.date),
        description: event.description || '',
        location: event.location || '',
      }));

      setEvents(formattedEvents);
    } catch (err) {
      console.error('Error fetching events: ', err);
      setError('Failed to fetch events');
    }
  };

  //fetch events once when component mounts
  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <div className="calendar-container">
      {error ? (
        <p>{error}</p>
      ) : (
        <div className="calendar-event-wrapper">
          <Calendar
            value={date}
            onChange={setDate}
            //if you want to do something when the user navigates months:
            onActiveStartDateChange={({ activeStartDate }) => {
              // Optionally: fetch new events by month or year, if needed
            }}
            tileContent={({ date, view }) => {
              //show events that match this day
              if (view === 'month' || view === 'year') {
                const dayEvents = events.filter(
                  (event) =>
                    new Date(event.start).toDateString() === date.toDateString()
                );
                return (
                  <ul>
                    {dayEvents.map((evt) => (
                      <li key={evt.id}>{evt.title}</li>
                    ))}
                  </ul>
                );
              }
              return null;
            }}
          />
          <EventList events={events} selectedDate={date} />
        </div>
      )}
    </div>
  );
};

export default MyCalendar;
import { Link } from "react-scroll";
import data from "../../data/index.json";
import '../../style/MyOfferings.css';

export default function MyOfferings() {
  return (
    <section id="MyOfferings" className="offerings--section">
      <h2 className="offerings--section--heading">What I offer</h2>
      <div className="offerings--section--container">
        {data?.offerings?.map((item, index) => (
          <div key={index} className="offerings--section--card">
            <div className="offerings--section--img image-flip-container">
              <div className="image-flip-inner">
                <div className="image-flip-front">
                  <img src={item.src} alt="Offerings" />
                </div>
                <div className="image-flip-back">
                  {item.expectations && item.expectations.length > 0 && (
                    <>
                      <h4>What to Expect:</h4>
                      <ul>
                        {item.expectations.map((detail, idx) => (
                          <li key={idx}>{detail}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="offerings--section--card--content">
              <h3 className="offerings--section--title">
                {item.link ? (
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.title}
                  </a>
                ) : (
                  <Link
                    to="Contact"
                    spy={true}
                    smooth={true}
                    offset={30}
                    duration={500}
                  >
                    {item.title}
                  </Link>
                )}
              </h3>
              <p className="offerings--section--description">
                {item.link ? (
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.description}
                  </a>
                ) : (
                  <Link
                    to="Contact"
                    spy={true}
                    smooth={true}
                    offset={30}
                    duration={500}
                  >
                    {item.description}
                  </Link>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
